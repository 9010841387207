.app-navbar {
  position: absolute;
  width: 100%;
  z-index: +50000;

  .navbar-mobile {
    display: none;
  }

  .navbar-desktop {
    display: block;

    .navbar-desktop-items {
      display: flex;
      justify-content: space-between;
      width: 45%;
      margin: 0 auto;
      font-weight: 600;
    }
  }

  @media (max-width: 991.5px) {
    .navbar-desktop {
      display: none;
    }

    .navbar-mobile {
      display: block;
      background: rgba(0, 0, 0, 0.4);

      .nav-link {
        color: white;
      }

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.5%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
      }

      .navbar-toggler {
        border: 2px solid rgba(255, 255, 255, 0.5);
      }

      .navbar-toggler:focus {
        box-shadow: none;
      }
    }
  }
}

