.footer {
  .footer-row {
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    .footer-row {
      width: 30%;
    }
  }

  @media (max-width: 450px) {
    .footer-row {
      width: 40%;
    }
  }

  .footer-text {
    color: white;
  }
}