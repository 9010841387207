.homepage {
  .header {
    height: 100vh;
    background-image: url("../../public/assets/images/homepage/homepage-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .h-rule {
      position: absolute;
      width: 100%;
      bottom: -1%;
    }

    @media (max-width: 1200px) {
      .h-rule {
        bottom: -0.75%;
      }
    }

    @media (max-width: 900px) {
      .h-rule {
        bottom: -0.5%;
      }
    }

    @media (max-width: 520px) {
      .h-rule {
        bottom: -0.3%;
      }
    }

    .header-name {
      font-size: 125px;
      text-align: center;
      padding-top: 40vh;
      color: #565656;
    }

    @media (max-width: 769.5px) {
      .header-name {
        font-size: 100px;
        text-align: center;
        padding-top: 30vh;
        color: #565656;
      }
    }

    @media (max-width: 480px) {
      .header-name {
        font-size: 90px;
        text-align: center;
        padding-top: 25vh;
        color: #565656;
      }
    }

    .header-description {
      font-weight: 500;
    }

    .contact-us-btn {
      display: flex;
      justify-content: center;


      .primary-btn {
        font-size: 18px;
        width: 200px;
        height: 50px;
        background: linear-gradient(325deg, rgb(16, 16, 16) 0%, rgb(53, 53, 53) 100%);
        pointer-events: none;
        color: white;
      }

      .primary-btn:hover {
        color: white;
        pointer-events: none;
      }
    }
  }

  .our-services {
    background-image: url("../../public/assets/images/homepage/our-services/our-services-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .h-rule {
      position: absolute;
      width: 100%;
      bottom: -1%;
    }

    @media (max-width: 1399.5px) {
      .h-rule {
        bottom: -0.75%;
      }
    }

    @media (max-width: 1200px) {
      .h-rule {
        bottom: -0.5%;
      }
    }

    @media (max-width: 950px) {
      .h-rule {
        bottom: -0.3%;
      }
    }

    @media (max-width: 520px) {
      .h-rule {
        bottom: -0.2%;
      }
    }

    h3 {
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 30px;
    }

    @media (max-width: 1399.5px) {
      h3 {
        display: block;
      }
    }

    .our-services-img-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .our-services-card {
        position: relative;
        width: 300px;

        img {
          border-radius: 25px;
          border: 1px solid #6FACFA;
          filter: drop-shadow(0 0 1rem rgba(111, 172, 250, 0.4));
        }

        .our-services-card-info {
          position: absolute;
          width: 100%;
          bottom: 5px;

          h4 {
            color: white;
            font-size: 25px;
            text-align: center;
          }

          .our-services-card-description {
            color: white;
            text-align: center;
            font-size: 13px;
          }

          a {
            text-decoration: none;
            color: white;
            font-weight: 600;
          }
        }
      }
    }
  }

  .portfolio {
    background-image: url("../../public/assets/images/homepage/portfolio/portfolio-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .h-rule {
      position: absolute;
      width: 100%;
      bottom: -0.5%;
    }

    @media (max-width: 1399.5px) {
      .h-rule {
        bottom: -0.2%;
      }
    }

    @media (max-width: 1200px) {
      .h-rule {
        bottom: -0.1%;
      }
    }

    @media (max-width: 950px) {
      .h-rule {
        bottom: -0.1%;
      }
    }

    @media (max-width: 520px) {
      .h-rule {
        bottom: -0.08%;
      }
    }


    h3 {
      color: white;
      font-weight: 700;
      font-size: 30px;
    }

    .portfolio-description {
      width: 45%;
      color: white;
      margin: 0 auto;
      display: block;
    }

    .portfolio-gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .our-works {
    background-image: url("../../public/assets/images/homepage/our-works/our-works-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .h-rule {
      position: absolute;
      width: 100%;
      bottom: -1%;
    }

    @media (max-width: 1399.5px) {
      .h-rule {
        bottom: -0.75%;
      }
    }

    @media (max-width: 1200px) {
      .h-rule {
        bottom: -0.5%;
      }
    }

    @media (max-width: 950px) {
      .h-rule {
        bottom: -0.3%;
      }
    }

    @media (max-width: 520px) {
      .h-rule {
        bottom: -0.2%;
      }
    }

    h3 {
      color: white;
      font-weight: 700;
      font-size: 30px;
    }

    .our-works-description {
      width: 50%;
      color: white;
      margin: 0 auto;
      display: block;
    }

    .our-works-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
