* {
  font-family: 'Jura', sans-serif;

}

body {
  overflow-x: hidden;

}

.App {
  background: #0A0A0A;
  color: #02151A;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  h3, h4 {
    font-family: 'El Messiri', sans-serif;
  }

  .primary-btn {
    background: linear-gradient(325deg, rgba(125, 154, 255, 1) 0%, rgba(86, 204, 242, 1) 100%);
    border: none;
    color: #02151A;
    font-weight: 600;
    /*filter: drop-shadow(0 0 0.75rem rgba(125, 154, 255, 0.75));*/
  }
}
