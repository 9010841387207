.portfolio-gallery-card {
  .portfolio-card {
    width: 375px;
    position: relative;


    .img-hover {
      position: absolute;
      z-index: +10000;
      opacity: 1;
      transition: 500ms;
    }

    .img-hover:hover {
      opacity: 0;
      transition: 500ms;
    }

    .img-border {
      border: 3px solid #6FACFA;
      position: relative;
      z-index: +500;
    }

    .card-info {
      height: 40px;
      background: linear-gradient(325deg, rgba(125, 154, 255, 1) 0%, rgba(86, 204, 242, 1) 100%);
      position: absolute;
      width: 375px;
      top: 335px;
      z-index: +1000;

      .full-screen-btn {
        cursor: pointer;
        margin-right: 5%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
      }

      .portfolio-gallery-description {
        font-size: 15px;
        text-align: justify;
      }
    }
  }
}